<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import moment from "moment";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      page: 1,
      tot_pages: 0,
      limit: 20,
      search: "",
      selectedSupplier: "",
      pay_amount: 0,
      supplierInvoices: [],
      advancedTotal: 0,
      suppliers: [],
      supplier: {},
      supplierTotal: 0,
      addAsset: [{}],
      addAccounts: [{}],
    };
  },
  computed: {
    filteredSuppliers() {
      return this.suppliers.filter((supplier) => {
        return supplier;
      });
    },
  },
  methods: {
    getCashAccount() {
      this.http.post("transactions/cash-accounts", {}).then((res) => {
        this.currentAssets = res.data;
      });
    },
    deleteOption(index) {
      this.addAsset.splice(index, 1);
    },
    getSuppliers() {
      console.log(this.search);
      this.tot_pages = 0;
      this.http
        .post("agents/search", {
          search: this.search,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.suppliers = res.data.map((supplier) => {
            return this.$i18n.locale == "ar"
              ? `${supplier?.id}-${supplier?.name}`
              : `${supplier?.id}-${supplier?.name_en}`;
          });
        });
    },
    searchSupplier(page) {
      const id = this.selectedSupplier.split("-")[0];
      console.log(this.selectedSupplier);
      this.http
        .post("purchases/invoices", {
          limit: this.limit,
          page: this.page,
          id,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.supplierInvoices = res.data;
          this.supplier = res.agent;
          this.advancedTotal = res.advanced;
          this.supplierTotal = res.total[0]?.total_amount;
        });
    },
    payInvoices() {
      this.http
        .post("purchases/pay-invoices", {
          accounts: this.addAsset,
          agent_id: this.supplier?.id,
        })
        .then((res) => {
          if (res.status) {
            console.log("done");
            this.addAsset = [{}];
            this.supplierTotal = res.total[0]?.total;
            this.searchSupplier(this.page);
            this.getCashAccount();
          }
        });
    },
    // prePayments
    prePay() {
      console.log(this.addAccounts);
      this.http
        .post("purchases/prepay", {
          accounts: this.addAccounts,
          agent_id: this.supplier?.id,
        })
        .then((res) => {
          if (res.status) {
            console.log("done");
            this.addAccounts = [{}];
            this.searchSupplier(this.page);
            this.getCashAccount();
          }
        });
    },
  },
  created() {
    this.getCashAccount();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.payments.sub.payment_made')"
    />
    <div
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      class="form-group row mb-3"
    >
      <label
        class="col-md-2.5 col-form-label"
        for="userName"
        style="font-size: 16px; font-weight: bolder"
        >{{ $t("payments.payment_made_search") }}</label
      >
    </div>
    <div
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      class="form-group row mb-3"
    >
      <div class="row">
        <div class="col-3">
          <AutoComplete
            @keyup="getSuppliers()"
            v-model="selectedSupplier"
            :suggestions="filteredSuppliers"
            @complete="search = $event.query"
            style="color: #333 !important; border-radius: 10px !important"
          />
        </div>
        <div class="col">
          <div
            v-if="$i18n.locale == 'ar' ? 'ltr' : 'rtl'"
            class="col-md-4 w-50 mt-1"
          >
            <button
              @click="searchSupplier(1)"
              type="button"
              class="btn btn-primary mb-4"
            >
              {{ $t("popups.search") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="supplier.id">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("agents.id") }}</th>
                  <th scope="col">{{ $t("agents.name") }}</th>
                  <th scope="col">{{ $t("payment_made.total_invoices") }}</th>
                  <th scope="col">{{ $t("payment_made.total_advanced") }}</th>
                  <th scope="col">{{ $t("agents.phone") }}</th>
                  <th scope="col">{{ $t("agents.address") }}</th>
                  <!-- <th scope="col">{{ $t("payment_made.created") }}</th> -->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ supplier?.id }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? supplier?.name
                          ? supplier?.name
                          : supplier?.name_en
                        : supplier?.name_en
                        ? supplier?.name_en
                        : supplier?.name
                    }}
                  </td>
                  <td>
                    {{
                      parseFloat(
                        supplierTotal ? supplierTotal : 0
                      ).toLocaleString()
                    }}
                  </td>
                  <td
                    :style="{
                      color:
                        parseFloat(advancedTotal) < 0
                          ? '#26d526'
                          : parseFloat(advancedTotal) > 0
                          ? 'red'
                          : '',
                    }"
                  >
                    {{ parseFloat(advancedTotal).toLocaleString() }}
                  </td>
                  <td>{{ supplier?.phone }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? supplier?.address
                          ? supplier?.address
                          : supplier?.address_en
                        : supplier?.address_en
                        ? supplier?.address_en
                        : supplier?.address
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-between">
            <div></div>
            <div>
              <button
                v-if="supplierInvoices.length > 0"
                data-bs-toggle="modal"
                data-bs-target="#payModal"
                class="btn btn-primary"
              >
                {{ $t("payment_made.pay_invoices") }}
              </button>
              <button
                v-if="supplierInvoices.length <= 0"
                data-bs-toggle="modal"
                data-bs-target="#prePayModal"
                class="btn btn-primary"
              >
                {{ $t("payment_made.loan_spplier") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="supplierInvoices.length > 0">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <!-- <th scope="col">{{ $t("payment_made.name") }}</th> -->
                  <th scope="col">{{ $t("payment_made.invoice_amount") }}</th>
                  <th scope="col">{{ $t("payment_made.paid") }}</th>
                  <th scope="col">{{ $t("payment_made.remaining") }}</th>
                  <th scope="col">{{ $t("payment_made.created") }}</th>
                  <th scope="col">{{ $t("payment_made.status") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(app, index) in supplierInvoices"
                  :key="app"
                  class=""
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ parseFloat(app?.total_balance).toLocaleString() }}</td>
                  <td>{{ parseFloat(app?.paid_balance).toLocaleString() }}</td>
                  <td>
                    {{
                      (
                        parseFloat(app?.total_balance) -
                        parseFloat(app?.paid_balance)
                      ).toLocaleString()
                    }}
                  </td>
                  <td>{{ app?.excution_time?.split("T")[0] }}</td>
                  <td>
                    {{
                      app?.total_balance == app?.paid_balance
                        ? $i18n.locale == "ar"
                          ? "تم الدفع"
                          : "paid"
                        : $i18n.locale == "ar"
                        ? "لم يتم الدفع"
                        : "not paid"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- end  table -->

    <!--   Purchases  Pagination     -->
    <ul
      v-if="supplierInvoices.length > 0"
      class="pagination pagination-rounded justify-content-center mb-2"
    >
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="searchSupplier(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="searchSupplier(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>

    <!--Start Pay Modal-->
    <div
      class="modal fade"
      id="payModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form @submit.prevent="payInvoices()">
            <div class="modal-header">
              <div class="d-flex justify-content-around">
                <h5 class="modal-title" id="exampleModalLabel">
                  <span>{{ $t("customers.total") }} : </span>
                  {{ parseFloat(supplierTotal).toLocaleString() }}
                </h5>
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row d-flex align-items-center mb-2">
                <div class="col">
                  <label for="asset_id">{{
                    $t("payment_made.pay_descr")
                  }}</label>
                </div>
                <div class="col d-flex justify-content-end">
                  <button
                    type="button"
                    @click="addAsset.push({})"
                    class="btn float-end"
                  >
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button>
                </div>
              </div>
              <div
                style="padding: 1rem; background: #f6f4f4"
                v-for="(asset, index) in addAsset"
                :key="asset"
              >
                <div class="row">
                  <div class="d-flex justify-content-between">
                    <select
                      v-model="asset.account"
                      class="form-select"
                      id="asset_id"
                      :placeholder="$t('emps.account')"
                    >
                      <option
                        :disabled="assets?.balance == 0"
                        :style="{
                          backgroundColor:
                            assets?.balance == 0 ? '#f1f1f1' : '',
                        }"
                        v-for="assets in currentAssets"
                        :key="assets"
                        :value="assets"
                      >
                        {{
                          $i18n.locale == "ar"
                            ? `(${assest?.name}) (${parseFloat(
                                assets?.balance
                              ).toLocaleString()})`
                            : `(${assets?.name_en}) (${parseFloat(
                                assets?.balance
                              ).toLocaleString()})`
                        }}
                      </option>
                    </select>
                    <input
                      :style="{
                        borderColor:
                          parseFloat(
                            asset?.account?.balance
                              ? asset?.account?.balance
                              : 1
                          ) < parseFloat(asset.value ? asset.value : 0)
                            ? 'red'
                            : '',
                      }"
                      v-model="asset.value"
                      type="number"
                      class="form-control mx-1"
                      :placeholder="$t('emps.amount')"
                    />
                    <span class="mx-2">
                      <button
                        type="button"
                        class="btn btn-danger"
                        @click="deleteOption(index)"
                        v-if="index > 0"
                      >
                        <i class="bx bx-trash"></i>
                      </button>
                      <button v-else disabled class="btn btn-danger">
                        <i class="bx bx-trash"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button
                :disabled="
                  !addAsset[0]?.hasOwnProperty('account_id') &&
                  !addAsset[0]?.hasOwnProperty('value')
                "
                type="submit"
                class="btn btn-primary"
              >
                {{ $t("popups.pay") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Pay Modal-->

    <!--Start prePay Modal-->
    <div
      class="modal fade"
      id="prePayModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form @submit.prevent="prePay()">
            <div class="modal-header">
              <h5>{{ $t("payment_made.prepay_title") }}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row d-flex align-items-center">
                <div class="col">
                  <label for="asset_id">{{
                    $t("payment_made.pay_descr")
                  }}</label>
                </div>
                <div class="col d-flex justify-content-end">
                  <button
                    type="button"
                    @click="addAccounts.push({})"
                    class="btn float-end"
                  >
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button>
                </div>
              </div>
              <div
                style="padding: 1rem; background: #f6f4f4"
                v-for="(account, index) in addAccounts"
                :key="account"
              >
                <div class="row">
                  <div class="d-flex justify-content-between">
                    <select
                      v-model="account.account"
                      class="form-select"
                      id="account_id"
                      :placeholder="$t('emps.account')"
                    >
                      <option
                        :disabled="asset?.balance == 0"
                        :style="{
                          backgroundColor: asset?.balance == 0 ? '#f1f1f1' : '',
                        }"
                        v-for="asset in currentAssets"
                        :key="asset"
                        :value="asset"
                      >
                        {{
                          $i18n.locale == "ar"
                            ? `(${asset?.name}) (${parseFloat(
                                asset?.balance
                              ).toLocaleString()})`
                            : `(${asset?.name_en}) (${parseFloat(
                                asset?.balance
                              ).toLocaleString()})`
                        }}
                      </option>
                    </select>
                    <input
                      :style="{
                        borderColor:
                          parseFloat(
                            account?.account?.balance
                              ? account?.account?.balance
                              : 1
                          ) < parseFloat(account.value ? account.value : 0)
                            ? 'red'
                            : '',
                      }"
                      v-model="account.value"
                      type="number"
                      class="form-control mx-1"
                      :placeholder="$t('emps.amount')"
                    />
                    <span class="mx-2">
                      <button
                        class="btn btn-danger"
                        @click="deleteOption(index)"
                        v-if="index > 0"
                      >
                        <i class="bx bx-trash"></i>
                      </button>
                      <button v-else disabled class="btn btn-danger">
                        <i class="bx bx-trash"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button
                :disabled="
                  !addAccounts[0]?.hasOwnProperty('id') &&
                  !addAccounts[0]?.hasOwnProperty('value')
                "
                type="submit"
                class="btn btn-primary"
              >
                {{ $t("popups.pay") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End prePay Modal-->
  </Layout>
</template>
